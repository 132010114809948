<template>
  <div class="p-mileNavigation">
    <div class="c-section c-section--fluid c-section--white01Bg">
      <div class="c-section__inner">
        <h1 class="c-title4 c-title4--grey01">{{ $wordings.mypage }}</h1>

        <ul class="p-mileNavigation__list">
          <li>
            <a
              :href="
                $customUrlScheme.page(
                  'ec',
                  `url=${ONLINESTORE_DOMAIN}/member_mypage.html`
                )
              "
              v-ripple="$utilities.getRippleOptions('light')">
              <span class="p-mileNavigation__list__icon">
                <Icon name="userSetting" />
              </span>
              <span class="p-mileNavigation__list__label">{{
                $wordings.accountInfo
              }}</span>
            </a>
          </li>
          <li>
            <a
              :href="$customUrlScheme.page('standalone', 'url=/mypage/gift')"
              v-ripple="$utilities.getRippleOptions('light')">
              <span class="p-mileNavigation__list__icon">
                <Icon name="present" />
              </span>
              <span class="p-mileNavigation__list__label">{{
                $wordings.presentBox
              }}</span>
            </a>
          </li>
        </ul>

        <ul :class="tabsClassObject">
          <li>
            <button
              type="button"
              :class="tabClassObject"
              @click="activateTabContent('onlineStore')"
              v-ripple>
              <Icon name="present" />
              <span class="c-tab__label">{{ $wordings.onlineStore }}</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              :class="tabClassObject"
              @click="activateTabContent('notice')"
              v-ripple>
              <Icon name="bell" />
              <span class="c-tab__label">{{ $wordings.notice }}</span>
            </button>
          </li>
        </ul>

        <div class="p-mileNavigation__outline">
          <template v-if="!$_loading_state">
            <template v-if="isActiveOnlineStoreTab">
              <div
                v-if="presents.length"
                class="c-cards c-cards--tiled c-cards--mb0">
                <div
                  v-for="(item, index) in presents"
                  :key="index"
                  class="c-cards__inner">
                  <CouponCard
                    v-if="index < 3"
                    :href="generateUrl(item.id, 'coupon')"
                    :src="item.reward.image_url"
                    :title="item.reward.name"
                    :subtitle="getCategory(item.reward.type, 'coupon')"
                    :noticed="item.is_new"
                    tiled
                    @click="markAsRead(item.id, 'present')" />
                </div>
                <!-- /.c-cards__inner -->
              </div>
              <!-- /.c-cards -->
              <Empty
                v-else
                icon-name="present"
                :text="`現在${$wordings.onlineStore}で使える
${$wordings.present}はありません`" />
            </template>

            <template v-else>
              <div
                v-if="notices.length"
                class="c-cards c-cards--tiled c-cards--mb0">
                <div
                  v-for="(item, index) in notices"
                  :key="index"
                  class="c-cards__inner">
                  <NoticeCard
                    v-if="index < 3"
                    :href="generateUrl(item.id, 'notice')"
                    :src="item.image_url"
                    :title="item.title"
                    :subtitle="getCategory(item.category_type, 'notice')"
                    :date="formatDate(item.publish_start_at)"
                    :noticed="!item.read"
                    tiled
                    @click="markAsRead(item.id, 'notice')" />
                </div>
                <!-- /.c-cards__inner -->
              </div>
              <!-- /.c-cards -->
              <Empty v-else icon-name="bell" text="現在お知らせはありません" />
            </template>
          </template>

          <template v-else>
            <div class="p-mileNavigation__outline__inner">
              <Spinner />
            </div>
          </template>
        </div>
        <!-- /.p-mileNavigation__outline -->
      </div>
      <!-- /.c-section__inner -->

      <div class="c-section__button">
        <a
          :href="tabContentListLinkHref"
          class="c-textButton c-textButton--black01 c-textButton--center"
          v-ripple>
          {{ tabContentListLinkText }}
          <Icon
            name="arrowRight"
            class="c-textButton__icon c-textButton__icon--append" />
        </a>
      </div>
      <!-- /.c-section__button -->
    </div>
    <!-- /.c-section -->
  </div>
  <!-- /.p-mileNavigation -->
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, ref, computed } from '@vue/composition-api';
import {
  SystemDialogMessage,
  MessageCategoryType,
  RewardType
} from '@/constants/enums';

export default defineComponent({
  components: {
    CouponCard: () => import('@/components/mypage/CouponCard.vue'),
    NoticeCard: () => import('@/components/mypage/NoticeCard.vue')
  },

  setup: (props, context) => {
    const ONLINESTORE_DOMAIN = process.env.VUE_APP_EC_BASE_URL;
    const notices = ref([]);
    const presents = ref([]);

    const tabsBlockName = 'c-tabs';
    const tabBlockName = 'c-tab';
    const activeTabContent = ref('onlineStore');

    const tabsClassObject = computed(() => [
      tabsBlockName,
      tabsPositionClass.value
    ]);
    const tabClassObject = computed(() => [
      tabBlockName,
      noticedTabClass.value
    ]);

    const tabsPositionClass = computed(() => {
      if (isActiveOnlineStoreTab.value) {
        return tabsBlockName + '--activeLeft';
      } else {
        return tabsBlockName + '--activeRight';
      }
    });

    const noticedTabClass = computed(() => {
      if (!props.noticed) return;
      return tabBlockName + ' ' + tabBlockName + '--noticed';
    });

    const isActiveOnlineStoreTab = computed(
      () => activeTabContent.value === 'onlineStore'
    );

    const tabContentListLinkText = computed(() => {
      return isActiveOnlineStoreTab.value
        ? context.root.$wordings.presentBox + context.root.$wordings.more
        : context.root.$wordings.notice + '一覧' + context.root.$wordings.more;
    });

    const tabContentListLinkHref = computed(() => {
      return isActiveOnlineStoreTab.value
        ? context.root.$customUrlScheme.page('standalone', 'url=/mypage/gift')
        : context.root.$customUrlScheme.page('standalone', 'url=/notice');
    });

    const activateTabContent = (targetContentName) => {
      activeTabContent.value = targetContentName;
    };

    const fetchNoticeList = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('notice')
          .getNotices();
        notices.value = data.data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    const fetchOnlineStoreList = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('present')
          .getPresents();
        presents.value = data.data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    /**
     * お知らせを既読にする
     *
     * NOTE: APIでのデータ更新ではなく、あくまでUI要件を満たすための対応
     */
    const markAsRead = (id, type) => {
      const notice = notices.value.find((notice) => notice.id === id);
      const present = presents.value.find((present) => present.id === id);
      if (type === 'notice') {
        if (!notice) return;
        notice.read = true;
      } else {
        if (!present) return;
        present.is_new = false;
      }
    };

    const generateUrl = (id, type) => {
      if (type === 'notice') {
        return context.root.$customUrlScheme.page(
          'standalone',
          `url=/notice/detail/${id}`
        );
      } else {
        return context.root.$customUrlScheme.page(
          'detail',
          `url=/mypage/gift/detail/${id}`
        );
      }
    };

    const formatDate = (datetime) =>
      context.root.$utilities.localizeDate(datetime);

    const getCategory = (categoryType, contentType) => {
      if (contentType === 'notice') {
        return MessageCategoryType.getTypeName(categoryType);
      } else {
        return RewardType.getTypeName(categoryType);
      }
    };

    fetchNoticeList();
    fetchOnlineStoreList();

    return {
      WORDS,
      ONLINESTORE_DOMAIN,
      notices,
      presents,
      tabsBlockName,
      isActiveOnlineStoreTab,
      tabContentListLinkText,
      tabContentListLinkHref,
      tabClassObject,
      tabsClassObject,
      tabsPositionClass,
      noticedTabClass,
      activateTabContent,
      markAsRead,
      generateUrl,
      formatDate,
      getCategory
    };
  }
});
</script>
