var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-mileNavigation" }, [
    _c(
      "div",
      { staticClass: "c-section c-section--fluid c-section--white01Bg" },
      [
        _c("div", { staticClass: "c-section__inner" }, [
          _c("h1", { staticClass: "c-title4 c-title4--grey01" }, [
            _vm._v(_vm._s(_vm.$wordings.mypage)),
          ]),
          _c("ul", { staticClass: "p-mileNavigation__list" }, [
            _c("li", [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: _vm.$utilities.getRippleOptions("light"),
                      expression: "$utilities.getRippleOptions('light')",
                    },
                  ],
                  attrs: {
                    href: _vm.$customUrlScheme.page(
                      "ec",
                      "url=" + _vm.ONLINESTORE_DOMAIN + "/member_mypage.html"
                    ),
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "p-mileNavigation__list__icon" },
                    [_c("Icon", { attrs: { name: "userSetting" } })],
                    1
                  ),
                  _c("span", { staticClass: "p-mileNavigation__list__label" }, [
                    _vm._v(_vm._s(_vm.$wordings.accountInfo)),
                  ]),
                ]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: _vm.$utilities.getRippleOptions("light"),
                      expression: "$utilities.getRippleOptions('light')",
                    },
                  ],
                  attrs: {
                    href: _vm.$customUrlScheme.page(
                      "standalone",
                      "url=/mypage/gift"
                    ),
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "p-mileNavigation__list__icon" },
                    [_c("Icon", { attrs: { name: "present" } })],
                    1
                  ),
                  _c("span", { staticClass: "p-mileNavigation__list__label" }, [
                    _vm._v(_vm._s(_vm.$wordings.presentBox)),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("ul", { class: _vm.tabsClassObject }, [
            _c("li", [
              _c(
                "button",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  class: _vm.tabClassObject,
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.activateTabContent("onlineStore")
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { name: "present" } }),
                  _c("span", { staticClass: "c-tab__label" }, [
                    _vm._v(_vm._s(_vm.$wordings.onlineStore)),
                  ]),
                ],
                1
              ),
            ]),
            _c("li", [
              _c(
                "button",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  class: _vm.tabClassObject,
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.activateTabContent("notice")
                    },
                  },
                },
                [
                  _c("Icon", { attrs: { name: "bell" } }),
                  _c("span", { staticClass: "c-tab__label" }, [
                    _vm._v(_vm._s(_vm.$wordings.notice)),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "p-mileNavigation__outline" },
            [
              !_vm.$_loading_state
                ? [
                    _vm.isActiveOnlineStoreTab
                      ? [
                          _vm.presents.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "c-cards c-cards--tiled c-cards--mb0",
                                },
                                _vm._l(_vm.presents, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "c-cards__inner",
                                    },
                                    [
                                      index < 3
                                        ? _c("CouponCard", {
                                            attrs: {
                                              href: _vm.generateUrl(
                                                item.id,
                                                "coupon"
                                              ),
                                              src: item.reward.image_url,
                                              title: item.reward.name,
                                              subtitle: _vm.getCategory(
                                                item.reward.type,
                                                "coupon"
                                              ),
                                              noticed: item.is_new,
                                              tiled: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.markAsRead(
                                                  item.id,
                                                  "present"
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _c("Empty", {
                                attrs: {
                                  "icon-name": "present",
                                  text:
                                    "現在" +
                                    _vm.$wordings.onlineStore +
                                    "で使える\n" +
                                    _vm.$wordings.present +
                                    "はありません",
                                },
                              }),
                        ]
                      : [
                          _vm.notices.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "c-cards c-cards--tiled c-cards--mb0",
                                },
                                _vm._l(_vm.notices, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "c-cards__inner",
                                    },
                                    [
                                      index < 3
                                        ? _c("NoticeCard", {
                                            attrs: {
                                              href: _vm.generateUrl(
                                                item.id,
                                                "notice"
                                              ),
                                              src: item.image_url,
                                              title: item.title,
                                              subtitle: _vm.getCategory(
                                                item.category_type,
                                                "notice"
                                              ),
                                              date: _vm.formatDate(
                                                item.publish_start_at
                                              ),
                                              noticed: !item.read,
                                              tiled: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.markAsRead(
                                                  item.id,
                                                  "notice"
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _c("Empty", {
                                attrs: {
                                  "icon-name": "bell",
                                  text: "現在お知らせはありません",
                                },
                              }),
                        ],
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "p-mileNavigation__outline__inner" },
                      [_c("Spinner")],
                      1
                    ),
                  ],
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "c-section__button" }, [
          _c(
            "a",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              staticClass:
                "c-textButton c-textButton--black01 c-textButton--center",
              attrs: { href: _vm.tabContentListLinkHref },
            },
            [
              _vm._v(" " + _vm._s(_vm.tabContentListLinkText) + " "),
              _c("Icon", {
                staticClass: "c-textButton__icon c-textButton__icon--append",
                attrs: { name: "arrowRight" },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }